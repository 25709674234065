import { useEffect, useState } from 'react'
import App from '../../components/app/App'
import ApiServices from '../../services/api/ApiServices'
import Contact from './Contact'
import Button from '../../components/button/Button'
import Title from './Title'
import Models from '../../services/api/Models'
import Resource from './Resource'
import { Banners } from './Banners'
import { Services } from './Services'

const Inners = [
    { title: 'Banner', C: Banners },
    { title: 'Đầu trang', C: Title },
    { title: 'Liên hệ', C: Contact },
    { title: 'Resource', C: Resource },
    { title: 'Dịch vụ', C: Services },
]

const HomeEdit = () => {
    const [home, setHome] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchHomePage = async () => {
            if (loading) return
            if (home?._id) return

            setLoading(true)
            const { success, data, message } = await ApiServices.getHomePage()
            setLoading(false)

            if (!success) return alert(message)

            setHome(data)
        }

        fetchHomePage()
    }, [home, loading])

    const submit = async () => {
        if (loading) return

        setLoading(true)
        const { success, message } = await ApiServices.updateOne({
            model: Models.homePage,
            ...home,
        })
        setLoading(false)

        if (!success) return alert(message)
        window.location.reload()
    }

    const onChangeInput = ({ target: { value, name } }) =>
        setHome(f => ({ ...f, [name]: value }))

    const innerProps = { home, setHome, onChangeInput }
    const renderInners = () =>
        Inners.map(({ C, title }, i) => (
            <div key={i} className="p-4 border bg-white rounded mb-4">
                <div className="font-bold text-blue-800">{title}</div>
                <C {...innerProps} />
            </div>
        ))

    return (
        <App>
            {renderInners()}
            <div className="flex justify-center">
                <Button
                    disabled={loading}
                    onClick={submit}
                    className="px-8 shadow-lg"
                >
                    Lưu
                </Button>
            </div>
        </App>
    )
}

export default HomeEdit
