import Input from '../../components/input/Input'

const Resource = ({ home, onChangeInput }) => {
    if (!home._id) return

    return (
        <div>
            <img src="/home/resource.png" alt="" />
            <span className="text-xs text-gray-400">
                Ảnh minh hoạ vị trí của text
            </span>
            <Input
                space
                id="home-reasonHeading"
                name="reasonHeading"
                placeholder="Tiêu đề"
                value={home.reasonHeading}
                onChange={onChangeInput}
            />
            <Input
                space
                textarea
                id="home-reasonMessage"
                name="reasonMessage"
                placeholder="Chú thích"
                value={home.reasonMessage}
                onChange={onChangeInput}
            />
            <Input
                space
                textarea
                id="home-resourceMessage"
                name="resourceMessage"
                placeholder="Chú thích 2"
                value={home.resourceMessage}
                onChange={onChangeInput}
            />
        </div>
    )
}

export default Resource
