import { useRef, useState } from 'react'
import App from '../../components/app/App'
import Button from '../../components/button/Button'
import ApiServices from '../../services/api/ApiServices'

export const UploadImage = () => {
    const [url, setUrl] = useState('')
    const inputRef = useRef()

    const upload = async () => {
        const { files } = inputRef?.current
        if (!files?.length) return alert('Mời nhập file')

        const f = new FormData()
        f.append('file', files[0])

        const { success, data, message } = await ApiServices.uploadFile(f)

        if (!success) return alert(message)

        setUrl(ApiServices.getImageUrl(data?.file?.fileName))
    }

    return (
        <App>
            <div>Upload ảnh ở đây để lấy link</div>
            <input type="file" className="w-full" ref={inputRef} />
            <Button className="mt-4" onClick={upload}>
                Upload
            </Button>

            {!!url && (
                <div className="mt-4">
                    <div className="font-bold text-blue-700">
                        URL: <br /> {url}
                    </div>
                    <img src={url} alt="" />
                </div>
            )}
        </App>
    )
}
