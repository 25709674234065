import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import App from '../../components/app/App'
import ApiServices from '../../services/api/ApiServices'
import Input from '../../components/input/Input'
import Button from '../../components/button/Button'
import Models from '../../services/api/Models'

const MenuItem = () => {
    const params = useParams()
    const { id } = params || {}
    const [menu, setMenu] = useState({})
    const [pages, setPages] = useState([])

    useEffect(() => {
        if (!id) return

        const fetch = async () => {
            const { success, data, message } = await ApiServices.getMenu({ id })
            if (!success) return alert(message)

            setPages(data?.pages)
            setMenu(data?.menu)
        }

        fetch()
    }, [id])

    const renderPage = (page, i) => (
        <Link
            to={`/page/${page.url}`}
            className="bg-blue-800 text-white px-3 py-2 mr-2 shadow-lg rounded"
            key={i}
        >
            {page.title}
        </Link>
    )

    const clickEdit = async () => {
        if (!menu.name || !menu.url) return alert('Điền đủ tên và url')
        if (menu.url[0] !== '/') return alert('Sai URL')
        if (id) {
            const { success, message } = await ApiServices.updateOne({
                model: Models.menu,
                ...menu,
            })

            if (!success) return alert(message)
            window.location.href = '/menu'
        }

        const { success, message } = await ApiServices.handle({
            method: 'post',
            url: '/menu',
            data: menu,
        })

        if (!success) return alert(message)
        window.location.href = '/menu'
    }

    const clickDelete = async () => {
        if (!window.confirm('Bạn có chăc xóa không')) return

        const { success, message } = await ApiServices.updateOne({
            model: Models.menu,
            _adminDelete: true,
            ...menu,
        })

        if (!success) return alert(message)
        window.location.href = '/menu'
    }

    return (
        <App>
            <div className="p-4 mb-4 bg-white rounded">
                <Input
                    value={menu.name}
                    placeholder="Tên"
                    onChange={e =>
                        setMenu(f => ({ ...f, name: e.target.value }))
                    }
                />

                <Input
                    value={menu.url}
                    placeholder="URL"
                    onChange={e =>
                        setMenu(f => ({ ...f, url: e.target.value }))
                    }
                />
                <small className="text-gray-400 text-xs">
                    URL phải có / ở đâu
                </small>
                <br />
                <Button onClick={clickEdit}>{id ? 'Sửa' : 'Add'}</Button>
                {!!id && (
                    <>
                        <Link to={`/page/add/new/${menu?._id}`}>
                            <Button className="ml-32">Thêm trang</Button>
                        </Link>
                        <Button
                            className="ml-32 bg-blue-600"
                            onClick={clickDelete}
                        >
                            Xóa
                        </Button>
                    </>
                )}
            </div>
            {pages.map(renderPage)}
        </App>
    )
}

export default MenuItem
