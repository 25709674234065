import React from 'react'
import ReactDOM from 'react-dom/client'
import './scss/main.scss'
import reportWebVitals from './reportWebVitals'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Login from './screens/login/Login'
import HomeEdit from './screens/home-edit/HomeEdit'
import Company from './screens/company/Company'
import Menu from './screens/menu/Menu'
import MenuItem from './screens/menu-item/MenuItem'
import Page from './screens/page/Page'
import NewPost from './screens/new-post/NewPost'
import PostList from './screens/post-list/PostList'
import { UploadImage } from './screens/upload-image/UploadImage'
import AppEdit from './screens/app-edit/AppEdit'

const router = createBrowserRouter([
    { path: '/login', element: <Login /> },
    { path: '/', element: <HomeEdit /> },
    { path: '/app', element: <AppEdit /> },
    { path: '/company', element: <Company /> },
    { path: '/menu', element: <Menu /> },
    { path: '/menu/new', element: <MenuItem /> },
    { path: '/menu/:id', element: <MenuItem /> },
    { path: '/page/:id', element: <Page /> },
    { path: '/page/add/new/:menu', element: <Page /> },
    { path: '/post/:id', element: <NewPost /> },
    { path: '/post/new', element: <NewPost /> },
    { path: '/post', element: <PostList /> },
    { path: '/upload', element: <UploadImage /> },
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
