import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const links = [
    { url: '/', text: 'Trang chủ' },
    { url: '/company', text: 'Công ty' },
    { url: '/menu', text: 'Menu' },
    { url: '/post', text: 'Bài viết' },
    { url: '/upload', text: 'Up ảnh' },
    { url: '/app', text: 'Cài đặt trang web' },
]

const loginLinks = [
    {
        url: '/login',
        text: 'Đăng nhập',
    },
]

const App = ({ noAuth, children }) => {
    const navigate = useNavigate()
    const token = localStorage.getItem('token')

    useEffect(() => {
        if (!navigate) return
        if (noAuth) return

        if (!token) navigate('/login')
    }, [noAuth, navigate, token])

    if (!noAuth && !token) return null

    return (
        <div className="min-h-screen bg-gray-100">
            <div className="bg-blue-800">
                <div className="container mx-auto p-4">
                    {(token ? links : loginLinks).map((link, i) => (
                        <Link key={i} to={link.url}>
                            <span className="text-white font-bold mr-8">
                                {link.text}
                            </span>
                        </Link>
                    ))}
                </div>
            </div>
            <div className="container mx-auto p-4">{children}</div>
        </div>
    )
}

export default App
