import Input from '../../components/input/Input'

const Contact = ({ home, onChangeInput }) => {
    if (!home._id) return

    return (
        <div>
            <img src="/home/contact.png" alt="" />
            <span className="text-xs text-gray-400">
                Ảnh minh hoạ vị trí của text
            </span>
            <Input
                space
                id="home-contactHeading"
                name="contactHeading"
                placeholder="Tiêu đề"
                value={home.contactHeading}
                onChange={onChangeInput}
            />
            <Input
                space
                textarea
                id="home-contactMessage"
                name="contactMessage"
                placeholder="Chú thích"
                value={home.contactMessage}
                onChange={onChangeInput}
            />
        </div>
    )
}

export default Contact
