const Models = {
    admin: 'admin',
    company: 'company',
    contactPage: 'contactPage',
    contactMessage: 'contactMessage',
    website: 'website',
    homePage: 'homePage',
    menu: 'menu',
    page: 'page',
    homeService: 'homeService',
}

export default Models
