import { useEffect, useState } from 'react'
import Button from '../../components/button/Button'
import Input from '../../components/input/Input'
import ApiServices from '../../services/api/ApiServices'
import Models from '../../services/api/Models'

export const Services = () => {
    const [services, setServices] = useState({})

    const updateService = async () => {
        console.log(services)

        const { success, message } = await ApiServices.updateOne({
            model: Models.homeService,
            ...services,
        })

        if (!success) return alert(message)
        alert('OK')
    }

    useEffect(() => {
        const fetchServices = async () => {
            const { success, data, message } = await ApiServices.getService()
            if (!success) return console.error(message)

            setServices(data)
        }
        fetchServices()
    }, [])

    const onChange = ({ target: { name, id, value } }) => {
        setServices(f => ({
            ...f,
            [name]: {
                ...f[name],
                [id]: value,
            },
        }))
    }

    const vn = (
        <div>
            <div>VN</div>
            <Input
                value={services?.vn?.heading}
                placeholder="heading"
                id="heading"
                name="vn"
                onChange={onChange}
            />
            <Input
                value={services?.vn?.description1}
                placeholder="description1"
                id="description1"
                name="vn"
                onChange={onChange}
            />
            <Input
                value={services?.vn?.description2}
                placeholder="description2"
                id="description2"
                name="vn"
                onChange={onChange}
            />
            <Input
                value={services?.vn?.text1}
                placeholder="text1"
                id="text1"
                name="vn"
                onChange={onChange}
            />
            <Input
                value={services?.vn?.text2}
                placeholder="text2"
                id="text2"
                name="vn"
                onChange={onChange}
            />
            <Input
                value={services?.vn?.title}
                placeholder="title"
                id="title"
                name="vn"
                onChange={onChange}
            />
        </div>
    )

    const onChangeJp = ({ target: { name, id, value } }) => {
        const key = `block${+name + 1}`

        setServices(f => ({
            ...f,
            jp: {
                ...f.jp,
                [key]: {
                    ...f.jp[key],
                    [id]: value,
                },
            },
        }))
    }

    const jp = (
        <div className="mt-8">
            <div>JP</div>
            {[
                services?.jp?.block1,
                services?.jp?.block2,
                services?.jp?.block3,
                services?.jp?.block4,
            ].map((item, i) => {
                if (!item) return null

                return (
                    <div key={i} className="my-2 py-2">
                        <Input
                            name={i}
                            id="heading"
                            value={item.heading}
                            onChange={onChangeJp}
                            placeholder="heading"
                        />
                        <Input
                            id="title"
                            name={i}
                            value={item.title}
                            onChange={onChangeJp}
                            placeholder="title"
                        />
                        <Input
                            id="text"
                            name={i}
                            value={item.text}
                            placeholder="text"
                            onChange={onChangeJp}
                        />
                    </div>
                )
            })}
        </div>
    )

    return (
        <div>
            {vn}
            {jp}
            <Button onClick={updateService}>Update service</Button>
        </div>
    )
}
