import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import App from '../../components/app/App'
import ApiServices from '../../services/api/ApiServices'
import Button from '../../components/button/Button'

const Menu = () => {
    const [menu, setMenu] = useState([])

    useEffect(() => {
        const fetch = async () => {
            const { success, data, message } = await ApiServices.getListMenu()
            if (!success) return alert(message)

            setMenu(data)
        }

        fetch()
    }, [])

    const renderMenu = (item, i) => (
        <Link to={`/menu${item.url}`}>
            <span className="mx-4 text-white bg-blue-800 rounded px-3 py-2 shadow-lg">
                {item.name}
            </span>
        </Link>
    )

    return (
        <App>
            <Link to={`/menu/new`}>
                <Button className="mb-8">Tạo menu mới</Button>
            </Link>
            <div className="mb-4">Danh sách menu</div>
            <div className="flex flex-wrap -mx-4 items-center">
                {menu.map(renderMenu)}
            </div>
        </App>
    )
}

export default Menu
