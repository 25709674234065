import classNames from 'classnames'

const Input = ({
    textarea,
    className,
    value,
    placeholder,
    id,
    space,
    ...opts
}) => {
    const inputProps = {
        id,
        placeholder,
        className: classNames(
            'w-full px-3 py-2 border focus:border-blue-800 duration-300 transition-all rounded mt-1 bg-white',
            className
        ),
        value: value || '',
        ...opts,
    }

    return (
        <div className={classNames({ 'py-2': space })}>
            <label htmlFor={id} className="font-semibold text-sm">
                {placeholder}
            </label>
            {!textarea ? (
                <input {...inputProps} />
            ) : (
                <textarea {...inputProps} />
            )}
        </div>
    )
}

export default Input
