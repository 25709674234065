import App from '../../components/app/App'
import { useEffect, useState } from 'react'
import ApiServices from '../../services/api/ApiServices'
import { Link } from 'react-router-dom'
import Button from '../../components/button/Button'

const PostList = () => {
    const [posts, setPosts] = useState([])

    useEffect(() => {
        const fetch = async () => {
            const { success, data, message } = await ApiServices.getAllPost()
            if (!success) return alert(message)

            setPosts(data?.posts)
        }

        fetch()
    }, [])

    const renderPost = post => (
        <div className="p-2 w-1/2" key={post._id}>
            <Link to={`/post/${post._id}`}>
                <div className="px-3 w-full py-2 bg-blue-800 text-white rounded text-center font-bold">
                    {post.title}
                </div>
            </Link>
        </div>
    )

    return (
        <App>
            <Link to={'/post/new'}>
                <Button>Bài viết mới</Button>
            </Link>
            <div className="-mx-2 w-full flex flex-wrap">
                {posts.map(renderPost)}
            </div>
        </App>
    )
}

export default PostList
