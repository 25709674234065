import classNames from 'classnames'

const Button = props => {
    const { children, className, fullWidth, ...opts } = props

    return (
        <button
            className={classNames(
                {
                    'w-full': fullWidth,
                },
                'bg-blue-800 px-3 py-2 rounded text-white hover:bg-blue-700 duration-300 transition-all disabled:bg-blue-500',
                className || ''
            )}
            {...opts}
        >
            {children}
        </button>
    )
}

export default Button
