import { useEffect, useState } from 'react'
import App from '../../components/app/App'
import ApiServices from '../../services/api/ApiServices'
import Input from '../../components/input/Input'
import Button from '../../components/button/Button'
import Models from '../../services/api/Models'

const heading = [
    ['Tiêu đề', 'pageHeading'],
    ['Chú thích 1', 'pageTitle'],
    ['Chú thích 2', 'pageDescription'],
]

const info = [
    ['社名', 'name'],
    ['代表取締役', 'director'],
    ['人材派遣・紹介先事例', 'major'],
    ['資本金', 'capital'],
    ['取引銀行', 'bank'],
    ['事業内容', 'business'],
    ['許可番号', 'contact'],
]

const Company = () => {
    const [loading, setLoading] = useState(false)
    const [company, setCompany] = useState({})

    useEffect(() => {
        const fetchCompany = async () => {
            const { success, data, message } = await ApiServices.getCompany()
            if (!success) return alert(message)

            setCompany(data)
        }

        fetchCompany()
    }, [])

    const onChange = ({ target: { value, name } }) =>
        setCompany(f => ({ ...f, [name]: value }))

    const onSubmit = async e => {
        e.preventDefault()
        if (loading) return

        setLoading(true)
        const { success, message } = await ApiServices.updateOne({
            model: Models.company,
            ...company,
        })
        setLoading(false)

        if (!success) return alert(message)
        window.location.reload()
    }

    const renderInfo = ([t, v], i) => {
        if (!company._id) return

        const value = company[v]

        return (
            <Input
                key={i}
                space
                value={value}
                onChange={onChange}
                name={v}
                placeholder={t}
                textarea
            />
        )
    }

    return (
        <App>
            <form onSubmit={onSubmit}>
                <div className="p-4 border bg-white rounded mb-4">
                    {heading.map(renderInfo)}
                </div>
                <div className="p-4 border bg-white rounded">
                    {info.map(renderInfo)}
                </div>
                <div className="flex justify-center mt-4">
                    <Button type="submit" className="px-8 shadow-lg">
                        Lưu
                    </Button>
                </div>
            </form>
        </App>
    )
}

export default Company
