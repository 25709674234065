import App from '../../components/app/App'
import { useEffect, useState } from 'react'
import ApiServices from '../../services/api/ApiServices'
import Input from '../../components/input/Input'
import Button from '../../components/button/Button'
import Models from '../../services/api/Models'

export const AppEdit = () => {
    const [app, setApp] = useState({})

    useEffect(() => {
        const fetch = async () => {
            const { success, data, message } = await ApiServices.getApp()
            if (!success) return alert(message)

            if (!data?.website) return

            setApp(data?.website)
        }

        fetch()
    }, [])

    const onChange = ({ target: { name, value } }) =>
        setApp(v => ({ ...v, [name]: value }))

    const save = async () => {
        const { success, message } = await ApiServices.updateOne({
            model: Models.website,
            ...app,
        })

        if (!success) return alert(message)

        window.location.reload()
    }

    return (
        <App>
            <Input
                value={app?.name}
                onChange={onChange}
                id={'name'}
                name={'name'}
                placeholder={'Tên web'}
            />
            <Input
                value={app?.description}
                onChange={onChange}
                id={'description'}
                name={'description'}
                placeholder={'Mô tả web'}
            />
            <Input
                value={app?.logo}
                onChange={onChange}
                id={'logo'}
                name={'logo'}
                placeholder={'Logo web'}
            />
            <Input
                value={app?.navLogo}
                onChange={onChange}
                id={'navLogo'}
                name={'navLogo'}
                placeholder={'Logo trên thanh navbar'}
            />
            <Input
                value={app?.headerTitle}
                onChange={onChange}
                id={'headerTitle'}
                name={'headerTitle'}
                placeholder={'Chữ trên header'}
            />
            <Input
                value={app?.headerText}
                onChange={onChange}
                id={'headerText'}
                name={'headerText'}
                placeholder={'Mô tả trên header'}
            />
            <Button className="mt-4" onClick={save}>
                Lưu
            </Button>
        </App>
    )
}

export default AppEdit
