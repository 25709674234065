import axios from 'axios'
import appConfig from '../../appConfig'

export const handleApi =
    ({ url, method }) =>
    async input => {
        const fullUrl = `${appConfig.server.host}${url}`
        const auth = localStorage.getItem('token')

        try {
            const { data: response } = await axios({
                method,
                url: fullUrl,
                ...(method.toLowerCase() === 'get'
                    ? { params: input || {} }
                    : { data: input || {} }),
                headers: {
                    authorization: `Bearer ${auth}`,
                },
            })

            const { success, data, message } = response

            return {
                success,
                data,
                message,
            }
        } catch (e) {
            return { success: false, message: e.message || e }
        }
    }

const ApiServices = {
    login: handleApi({
        url: '/admin/dang-nhap',
        method: 'post',
    }),
    getApp: handleApi({ method: 'get', url: '/ung-dung' }),
    getHomePage: handleApi({ method: 'get', url: '/trang-chu' }),
    getCompany: handleApi({ method: 'get', url: '/cong-ti' }),
    getListMenu: handleApi({ method: 'get', url: '/menu' }),
    updateOne: handleApi({ method: 'patch', url: '/admin/update' }),
    getMenu: handleApi({ method: 'get', url: '/menu/get' }),
    getPage: handleApi({ method: 'get', url: '/trang' }),
    editPost: handleApi({ method: 'post', url: '/bai-viet' }),
    getAllPost: handleApi({ method: 'get', url: '/bai-viet' }),
    createMenu: handleApi({ method: 'post', url: '/menu' }),
    getService: handleApi({
        method: 'get',
        url: '/trang-chu/dich-vu',
    }),
    handle: async ({ method, url, data, params }) =>
        handleApi({ url, method })(data || params),
    uploadFile: handleApi({
        method: 'post',
        url: '/admin/file',
        file: true,
    }),
    getImageUrl: fileName => {
        if (!fileName) return '/computer.jpg'

        if (fileName.includes('http')) return fileName

        if (fileName[0] === '#') return `/${fileName.slice(1, fileName.length)}`

        return `${appConfig.server.host}/image/${fileName}`
    },
}

export default ApiServices
