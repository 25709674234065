import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import App from '../../components/app/App'
import ApiServices from '../../services/api/ApiServices'
import Title from './Title'
import Button from '../../components/button/Button'
import Models from '../../services/api/Models'
import Block from './Block'

const Page = () => {
    const params = useParams()
    const { id, menu } = params || {}
    const [page, setPage] = useState({})

    useEffect(() => {
        if (!id) return

        const fetch = async () => {
            const { success, data, message } = await ApiServices.getPage({
                url: id,
            })
            if (!success) return alert(message)

            setPage(data?.page)
        }

        fetch()
    }, [id])

    const onSave = async () => {
        if (!page.title || !page.url) return alert('Thiếu title')

        const { success, message } = await ApiServices.updateOne({
            model: Models.page,
            menu: menu,
            ...page,
            blocks: (page.blocks || []).filter(item => item.text),
        })

        if (!success) return alert(message)

        window.location.href = `/page/${page.url}`
    }

    const addNew = async () => {
        if (page._id) return onSave()

        if (!page.title || !page.url) return alert('Thiếu title')

        const { success, message } = await ApiServices.updateOne({
            _new: true,
            model: Models.page,
            menu: menu,
            ...page,
        })

        if (!success) return alert(message)

        window.location.href = `/page/${page.url}`
    }

    return (
        <App>
            <Title page={page} setPage={setPage} />
            <Button onClick={addNew}>Save</Button>
            {!!id && <Block page={page} setPage={setPage} />}
            <div className="mt-8">
                <Button onClick={addNew}>Save</Button>
            </div>
        </App>
    )
}

export default Page
