import Input from '../../components/input/Input'

const Title = ({ page, setPage }) => {
    const onChangeInput = ({ target: { name, value } }) =>
        setPage(f => ({ ...page, [name]: value }))

    return (
        <div>
            <div>
                <Input
                    space
                    id="home-title"
                    name="title"
                    placeholder="Tiêu đề"
                    value={page.title}
                    onChange={onChangeInput}
                />
                <Input
                    space
                    textarea
                    id="home-description"
                    name="description"
                    placeholder="Chú thích"
                    value={page.description}
                    onChange={onChangeInput}
                />
                <Input
                    space
                    textarea
                    id="home-url"
                    name="url"
                    placeholder="url"
                    value={page.url}
                    onChange={onChangeInput}
                />
            </div>
        </div>
    )
}

export default Title
