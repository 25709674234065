import Input from '../../components/input/Input'

const Title = ({ home, onChangeInput }) => {
    if (!home._id) return

    return (
        <div>
            <img src="/home/title.png" alt="" />
            <span className="text-xs text-gray-400">
                Ảnh minh hoạ vị trí của text
            </span>
            <Input
                space
                id="home-title"
                name="title"
                placeholder="Tiêu đề"
                value={home.title}
                onChange={onChangeInput}
            />
            <Input
                space
                textarea
                id="home-description"
                name="description"
                placeholder="Chú thích"
                value={home.description}
                onChange={onChangeInput}
            />
        </div>
    )
}

export default Title
