import Input from '../../components/input/Input'
import Button from '../../components/button/Button'

const Block = ({ page, setPage }) => {
    const blocks = page?.blocks || []

    const clickAdd = () =>
        setPage(p => ({
            ...p,
            blocks: [...(p.blocks || []), { title: '', text: '', img: '' }],
        }))

    const onDelete = e =>
        setPage(p => ({ ...p, blocks: p.blocks.filter((_, i) => i !== e) }))

    const editBlock = (e, k, v) => {
        setPage(p => ({
            ...p,
            blocks: p.blocks.map((item, i) => {
                if (e !== i) return item

                return { ...item, [k]: v }
            }),
        }))
    }

    const renderBlock = (block, i) => {
        const onRemove = () => onDelete(i)
        const onChangeBlock = k => e => editBlock(i, k, e.target.value)

        return (
            <div className="mb-2 bg-white rounded shadow p-4" key={i}>
                <Input
                    placeholder="Title"
                    value={block.title}
                    onChange={onChangeBlock('title')}
                />
                <Input
                    textarea
                    placeholder="Text"
                    value={block.text}
                    onChange={onChangeBlock('text')}
                />
                <Input
                    placeholder="Image"
                    value={block.img}
                    onChange={onChangeBlock('img')}
                />
                <Button className="mt-2" onClick={onRemove}>
                    Delete block
                </Button>
            </div>
        )
    }

    return (
        <div className="mt-8">
            Block
            <br />
            <Button onClick={clickAdd}>Thêm block</Button>
            {blocks.map(renderBlock)}
        </div>
    )
}

export default Block
