import Input from '../../components/input/Input'

export const Banners = ({ home, onChangeInput }) => {
    const onChange = e => {
        const links = e.target.value.split('\n')
        onChangeInput({
            target: {
                name: 'banners',
                value: links.map(item => item.trim()),
            },
        })
    }

    return (
        <div>
            <Input
                textarea
                placeholder="Nhap link banners"
                value={home?.banners?.join('\n')}
                onChange={onChange}
            />
        </div>
    )
}
