import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import App from '../../components/app/App'
import Button from '../../components/button/Button'
import Input from '../../components/input/Input'
import ApiServices from '../../services/api/ApiServices'

const Login = () => {
    const [form, setForm] = useState({})
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        localStorage.clear()
    }, [])

    const onChange = ({ target: { name, value } }) =>
        setForm(f => ({ ...f, [name]: value }))

    const onSubmit = async e => {
        e.preventDefault()

        if (loading) return

        setLoading(true)
        const { success, data, message } = await ApiServices.login(form)
        setLoading(false)

        if (!success) return alert(message)

        const { token } = data
        localStorage.setItem('token', token)
        navigate('/')
    }

    return (
        <App noAuth>
            <form className="" onSubmit={onSubmit} disabled={loading}>
                <Input
                    space
                    id="user"
                    placeholder="Tài khoản"
                    name="username"
                    onChange={onChange}
                    value={form.username || ''}
                />
                <Input
                    space
                    id="pass"
                    placeholder="Mật khẩu"
                    name="password"
                    type="password"
                    value={form.password || ''}
                    onChange={onChange}
                />
                <div className="mt-4">
                    <Button fullWidth disabled={loading}>
                        Đăng nhập
                    </Button>
                </div>
            </form>
        </App>
    )
}

export default Login
