import { EditorState } from 'draft-js'
import { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import App from '../../components/app/App'
import Button from '../../components/button/Button'
import Input from '../../components/input/Input'
import ApiServices from '../../services/api/ApiServices'
import { stateToHTML } from 'draft-js-export-html'
import slugify from 'slugify'
import { useParams } from 'react-router'
import { stateFromHTML } from 'draft-js-import-html'

const NewPost = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [loading, setLoading] = useState(false)
    const params = useParams()
    const [post, setPost] = useState({})
    const { id: _id } = params

    useEffect(() => {
        const fetchPost = async () => {
            setLoading(true)
            const { success, data, message } = await ApiServices.getAllPost({
                _id,
            })
            setLoading(false)

            if (!success) return alert(message)
            setPost(data)
            setTitle(data?.title)
            setDescription(data?.description)
            setEditorState(
                EditorState.createWithContent(
                    stateFromHTML(data?.content || '')
                )
            )
        }

        fetchPost()
    }, [_id])

    const save = async () => {
        if (loading) return

        const form = {
            method: _id ? 'edit' : 'add',
            postId: post._id,
            title: title,
            content: stateToHTML(editorState.getCurrentContent()),
            description: description,
            slug: slugify(title),
        }

        setLoading(true)
        const { success, message } = await ApiServices.editPost(form)
        setLoading(false)

        if (!success) return alert(message)
        alert('OK')
        window.location.href = '/post'
    }

    return (
        <App>
            <Input
                id="title"
                placeholder="Tiêu đề"
                value={title}
                onChange={e => setTitle(e.target.value)}
            />
            <Input
                id="description"
                placeholder="Description"
                value={description}
                onChange={e => setDescription(e.target.value)}
            />
            <div className="p-4 bg-white rounded shadow-lg">
                <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={setEditorState}
                    placeholder="Nội dung post"
                />
            </div>
            <div className="mt-4 flex justify-center w-full">
                <Button className="shadow-lg" onClick={save}>
                    Lưu
                </Button>
            </div>
        </App>
    )
}

export default NewPost
